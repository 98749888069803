.gl-container,
.panel {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: white;
  border: 1px solid #cacaca;
  margin-bottom: 14px;
}
.gl-container .gl-inner {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.gl-container .gl-inner .gl-header,
.panel .panel-heading {
  border-bottom: 1px solid #cbcbcb;
  padding: 3px 7px 4px 7px;
  background: rgba(0, 0, 0, 0.05);
  -webkit-border-top-left-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-topright: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.gl-container .gl-inner .gl-header h2,
.gl-container .gl-inner .gl-header h3,
.gl-container .gl-inner .gl-header h4,
.gl-container .gl-inner .gl-header .h-title,
.panel .panel-heading .panel-title {
  font-size: 112.5%;
  font-weight: 500;
  margin: 0;
  padding: 0;
  line-height: 20px;
  color: #000;
}
.gl-container .gl-inner .gl-header h2 a,
.gl-container .gl-inner .gl-header h3 a,
.gl-container .gl-inner .gl-header h4 a,
.gl-container .gl-inner .gl-header .h-title a {
  color: #000 !important;
  display: block;
  text-decoration: none !important;
}
.gl-container .gl-inner .gl-header .thin {
  font-weight: 300;
  border-right: 1px solid #d4d4d4;
  padding-right: 7px;
  margin-right: 3px;
}
.gl-container .gl-inner .gl-header .thick {
  font-weight: 500;
}
.gl-container .gl-inner .gl-header .badge {
  margin: 2px 0 0 0 !important;
}

.gl-container .gl-inner .gl-headerblock {
  padding: 5px;
  border-bottom: 1px solid #d4d4d4;
}
.gl-container .gl-inner .gl-footerblock {
  padding: 15px;
  border-top: 1px solid #d4d4d4;
}

.gl-container .gl-inner .gl-content {
  padding: 5px;
  text-align:left;
}

.gl-container.fullsize .gl-inner .gl-header {
  margin-bottom: 0;
}
.gl-container.fullsize .gl-inner .gl-content {
  padding: 0;
}
.gl-container.fullsize .gl-inner .gl-content > img {
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

/* styles */
.gl-container.highlight .gl-inner .gl-header {
  background: #00b4dc;
}
.gl-container.highlight .gl-inner .gl-header h2,
.gl-container.highlight .gl-inner .gl-header h3,
.gl-container.highlight .gl-inner .gl-header .h-title {
  color: white;
}
.gl-container.highlight .gl-inner .gl-header .thin {
  border-right-color: #fff;
}

.gl-container .gl-inner .gl-footer {
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.gl-container .gl-inner .gl-bottombar {
  background: #f5f5f5;
  border-top: 1px solid #ebebeb;
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.3);
  padding: 10px;

  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.gl-container.mouseover {
  cursor: pointer;
  -webkit-transition: box-shadow 0.5s ease;
  -moz-transition: box-shadow 0.5s ease;
  -ms-transition: box-shadow 0.5s ease;
  -o-transition: box-shadow 0.5s ease;
  transition: box-shadow 0.5s ease;
}
.gl-container.mouseover:hover,
.gl-container.mouseover:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.gl-container.fullsize .gl-inner .gl-content table {
  margin: 0;
  border: none;
}

@media (min-width: 768px) {
  .gl-container.onecol .gl-inner .gl-content {
    padding: 5px 30px;
  }
  .gl-container.onecol .gl-inner .gl-headerblock {
    padding: 30px;
  }
  .gl-container.onecol .gl-inner .gl-headerblock h2 {
    padding: 0 !important;
    margin: 0 !important;
  }
}

;@import "sass-embedded-legacy-load-done:2400";