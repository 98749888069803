@import "../../Assets/stylesheets/bootstrap/variables";

.instagram-button-component {
    $size: 48px;
    $inset: $grid-gutter-width / 2 + 5px;

    width: $size;
    height: $size;
    display: block;
    position: absolute;
    top: ($size + $footer-floating-btn-spacing) * -2;
    right: $inset;
    display: flex;
    background: #ffffff;
    border-radius: 100px;
    text-decoration: none !important;
    justify-content: center;
    align-items: center;
    z-index: $zindex-dropdown - 1;
    @include z-depth(1);

    &:hover,
    &:focus {
        background: get-color('grey', '100');
    }
}
