@import "../../Assets/stylesheets/bootstrap/variables";

.main-menu-component {
    $item-spacing: .3rem;
    $link-color: $gray-200;
    z-index: 1;

    .navbar-toggler {
        $icon-bar-height: 2px;
        $icon-bar-spacing: 5px;
        $icon-size: $icon-bar-height * 3 + $icon-bar-spacing * 2;

        border: none;
        background: none;
        position: relative;
        display: block;
        padding: 0;
        width: $icon-size * 1.3;
        height: $icon-size;
        transform: rotate(0deg);
        transition: all .5s $timing-func-standard;
        cursor: pointer;

        span {
            display: block;
            position: absolute;
            height: $icon-bar-height;
            width: 100%;
            background: $white;
            border-radius: $icon-bar-height / 2;
            left: 0;
            transform: rotate(0deg);
            transition: all .25s $timing-func-standard;

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2), &:nth-child(3) {
                top: $icon-bar-height + $icon-bar-spacing;
            }

            &:nth-child(4) {
                top: $icon-bar-height * 2 + $icon-bar-spacing * 2;
            }
        }

        &:not(.collapsed) {
            span {
                &:nth-child(1) {
                    top: $icon-bar-spacing * 2;
                    width: 0%;
                    left: 50%;
                }

                &:nth-child(2) {
                    transform: rotate(45deg);
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                }

                &:nth-child(4) {
                    top: $icon-bar-spacing * 2;
                    width: 0%;
                    left: 50%;
                }
            }
        }
    }

    .nav-divider {
        margin: $item-spacing $item-spacing;
    }

    .nav-item {
        .nav-link {
            font-weight: $font-weight-normal;
            font-size: $font-size-base;
            line-height: 1em;
            color: $gray-200;
            padding: 0 .8rem;
            margin: 0 $item-spacing;
        }

        &.active {
            .nav-link {
                color: $white;
            }
        }

        img.emoji {
            display: inline-block;
            width: 20px;
            height: auto;
            margin-right: 5px;
        }
    }

    @import "styles/default";
    //@import "styles/pills";
}
