img:not([src]) {
    visibility: hidden;
}

img[data-src],
img[data-srcset] {
    display: block;
    min-height: 1px;
}

;@import "sass-embedded-legacy-load-done:2411";