#gl-global-search + .popover {
  max-width: 400px !important;
  width: 400px !important;
}

#gl-global-search + .popover .popover-content {
  padding: 0 !important;
}

#gl-global-search + .popover .popover-content .gl-gs-loading {
  padding: 10px;
}

.gl-global-search-resilts {

}

.gl-global-search-results .group-content {
  padding: 3px 5px;
}
.gl-global-search-results .search-results-row {
  cursor: pointer !important;
  background: transparent;
  margin-bottom: 5px;
}
.gl-global-search-results .search-results-row:hover,
.gl-global-search-results .search-results-row:focus {
  background: #f5f5f5;
}
.gl-global-search-results .more-results {
  border-top: 1px dotted #EBEBEB;
  padding: 3px 5px;
  font-size: 93.8%;
  margin-bottom: 10px;
}

.gl-global-search-results .group-title {
  background-color: #F7F7F7;
  border-bottom: 1px solid #EBEBEB;
  padding: 5px 14px;
}

.gl-globalsearch-results-events {
  line-height: 1em;
}
.gl-globalsearch-results-events .search-results-row .event-details {
  margin-left: 40px;
  margin-top: 2px;
}

.gl-globalsearch-results-partyphotos {

}

.gl-globalsearch-results-locations .search-results-row .location-details {
  margin-left: 40px;
  margin-top: 7px;
}

.gl-globalsearch-results-partyphotos .gl-pa-item {
  margin: 0 5px 5px 0;
}
.gl-globalsearch-results-partyphotos .gl-pa-item:nth-child(even) {
  margin-right: 0;
}

;@import "sass-embedded-legacy-load-done:2408";