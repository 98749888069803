@import "../../Assets/stylesheets/bootstrap/variables";

.infinite-scroll-view-component {
    margin-bottom: map-get($spacers, 5);

    .content {
    }

    .fetch-more {
        margin-top: map-get($spacers, 4);

        .button, .loading {
            display: none;
            flex-direction: row;
            justify-content: center;

            &.visible {
                display: flex;
            }
        }

        .button {
        }

        .loading {
            img {
                width: 80px;
                height: 80px;
                pointer-events: none;
                user-select: none;
            }
        }
    }

    .no-more-items {
        display: none;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: map-get($spacers, 5);

        &.visible {
            display: flex;
        }

        &::before, &::after {
            content: '';
            border-top: 0.05rem solid $gray-300;
            margin: 0 2rem 0 0;
            flex: 1 0 2rem;
        }

        &::after {
            margin: 0 0 0 2rem;
        }
    }
}
