input[type=text],
input[type=email],
input[type=date],
input[type=number],
input[type=password],
input[type=search],
textarea {
    $defaultShadow: inset 0 1px 1px rgba($black, .075);

    &.form-control {
        box-shadow: $defaultShadow;

        &:focus {
            box-shadow: $defaultShadow, 0 0 7px rgba($primary, .75);
        }
    }
}

.form-control.is-invalid {
    background: get-color('red', '50');
    border-color: get-color('red', '500');
}

.form-checkbox {
    @extend .form-check;
    padding-left: 0;
    
    .checkbox {
        display: flex;

        input {
            margin-top: 0.25rem;
            margin-right: map-get($spacers, 2);
        }
    }
}
;@import "sass-embedded-legacy-load-done:2404";