@import "../../Assets/stylesheets/bootstrap/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.event-list-component {
    $picture-size-lg: 74px;
    $picture-size-sm: 32px;

    .event-list {
        img.flyer {
            width: 72px;
            height: 72px;
        }
    }

    .date-grouped-event-list {
        .items-group {
            margin-bottom: map-get($spacers, 5);
            box-shadow: none;

            .items-header {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: map-get($spacers, 2);

                .group-title {
                    margin: 0;

                    .weekday {
                        font-weight: $font-weight-light;
                        border-right: 0.1rem solid $gray-300;
                        padding-right: map-get($spacers, 2);
                        margin-right: map-get($spacers, 2);
                    }

                    .date {
                    }
                }

                .item-count {
                    margin-left: auto;
                }
            }
        }

        &.style-card {
            .items-container {
                $spacing: map-get($spacers, 3);

                @mixin columns($columns: 1) {
                    grid-template-columns: repeat($columns, 1fr);
                }

                display: grid;
                grid-gap: $spacing;
                margin-bottom: $spacing;
                @include columns(1);

                @include media-breakpoint-up(sm) {
                    @include columns(2);
                }

                @include media-breakpoint-up(md) {
                    @include columns(2);
                }

                @include media-breakpoint-up(lg) {
                    @include columns(3);
                }

                @include media-breakpoint-up(xl) {
                    @include columns(4);
                }

                .event-item {
                    padding: 0;
                    border-bottom: none !important;
                    margin-bottom: 0 !important;

                    &:hover, &:focus {
                        background-color: $gray-100;
                    }

                    &:active {
                        background-color: $gray-100;
                    }

                    img.embed-responsive-item {
                        object-fit: cover;
                        object-position: center center;
                    }

                    .xcard-body {
                        padding: map-get($spacers, 2);
                    }

                    .tags {
                        padding-top: map-get($spacers, 2);
                        border-top: $gray-200 solid 1px;
                    }

                    .text-preview {
                        margin-top: map-get($spacers, 2);
                        padding-top: map-get($spacers, 2);
                        border-top: $gray-200 solid 1px;

                        h1, h2, h3, h4, h5, h6 {
                            font-size: $font-size-base;
                        }

                        p {
                            text-align: justify;
                            hyphens: auto;
                            font-size: $font-size-base !important;

                            @include media-breakpoint-up($breakpoint-desktop) {
                                font-size: $font-size-smaller !important;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    &:not(.is-premium) {
                        .text-preview {
                            p {
                                color: $gray-600;
                            }
                        }
                    }
                }

                .show-hidden-events-trigger {
                    display: flex;
                }
            }
        }
    }

    .event-item {
        position: relative;
        padding: map-get($spacers, 2);
        transition: create-transition();

        &:not(:last-child) {
            border-bottom: 0.1rem solid $gray-100;
        }

        &:hover, &:focus {
            background-color: $hover-background-default;
        }

        &:active {
            background-color: $active-background-default;
        }

        &.is-past {
            opacity: $opacity-past-moment;

            &:hover, &:focus {
                opacity: 1;
            }
        }

        &.is-hidden {
            display: none;
        }

        img.flyer {
            margin-right: map-get($spacers, 2);
            border-radius: $card-border-radius;
            width: 32px;
            height: 32px;
        }

        .content {
            //overflow: hidden;
            flex: 1;
            overflow: auto;

            .title, .metadata {
                margin-bottom: map-get($spacers, 1);
            }

            .title {
                a {
                    color: inherit;
                    font-weight: $font-weight-bold;
                    text-decoration: none;
                }
            }

            .metadata {
                $metadata-color: $gray-600;

                font-size: $font-size-small;
                color: $metadata-color;

                .separator {
                    display: block;
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background: $metadata-color;
                    margin: 0 map-get($spacers, 2);
                }

                .location-link {
                    font-weight: $font-weight-medium;
                    z-index: 3;
                }

                .icon {
                    font-size: $icon-size-sm;
                    margin-right: map-get($spacers, 1);
                }
            }

            .tags {
                white-space: normal;

                span {
                    margin-right: map-get($spacers, 1);
                    margin-bottom: map-get($spacers, 1);
                }
            }
        }

        .action-buttons {
            align-self: center;
            margin-left: map-get($spacers, 2);

            @include media-breakpoint-down($breakpoint-mobile) {
                & {
                    display: none;
                }
            }
        }

        &.is-premium,
        &.is-top {
            img.flyer {
                width: 72px;
                height: 72px;
            }

            .content {
                margin-top: 0.23rem;
            }
        }

        .progress {
            $height: 2px;
            $posBottom: $height * -1 + 1px;
            $progressbar-color: $brand-primary;
            $color: $white;
            $progressBarDashSpacing: $height * 3;

            display: flex;
            position: relative;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;
            white-space: nowrap;
            background: none;
            box-shadow: none;
            border-radius: 0;
            border: none;
            padding: 0;
            font-size: $font-size-small;
            padding: 0 0.1rem;
            overflow: visible;

            .progress-bar {
                position: absolute;
                bottom: $posBottom;
                left: 0;
                height: $height;
                z-index: 2;
                //border-radius: 10px;
                background-color: $progressbar-color;
            }

            /*&::before {
                position: absolute;
                display: block;
                content: '';
                bottom: $posBottom;
                right: 0;
                width: $height;
                height: $height;
                z-index: 2;
                //border-radius: 10px;
                background-color: $gray-400;
            }*/

            &::after {
                position: absolute;
                display: block;
                content: '';
                bottom: $posBottom;
                left: 0;
                right: 0;
                height: $height;
                z-index: 1;
                //border-radius: 10px;
                background-color: $gray-300;
            }

            .progress-label {
                z-index: 1;
                padding-right: 1px;
            }

            &.ends-soon {
                .date {
                    display: none;
                }
            }
        }
    }

    .day {
        @include transition;

        &:not(:last-child) {
            margin-bottom: map-get($spacers, 4);
        }

        &:focus,
        &:hover,
        &.active {
            @include z-depth(5);
        }

        &.past {
            opacity: $opacity-past-moment;
        }

        .day-body {
            .divider {
                border-bottom: .3rem solid $gray-100;
            }

            .item {
                display: flex;
                padding: map-get($spacers, 2);
                cursor: pointer;
                @include transition;

                &:not(:last-child) {
                    border-bottom: .1rem solid $gray-100;
                }

                &:focus,
                &:hover,
                &:active {
                    background: $gray-200;
                }

                &.hidden {
                    display: none;
                }

                &.product-premium {
                    $start: #f83600;
                    $end: #f9d423;
                    background-image: linear-gradient(120deg, rgba($start, .015) 0%, rgba($end, .4) 100%);
                }

                .picture {
                    position: relative;
                    margin-right: map-get($spacers, 2);

                    img {
                        border-radius: $border-radius;
                        width: $picture-size-lg;
                        height: $picture-size-lg;
                    }
                }

                .content {
                    flex: 1;
                    overflow: hidden;

                    .title {
                        font-size: 1rem;
                        line-height: 1.2em;
                        margin: 0 0 0 .1rem;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;

                        a {
                            color: $black;
                        }
                    }

                    .details {
                        margin: map-get($spacers, 1) 0;

                        &, .icon {
                            font-size: .9rem;
                        }

                        .icon {
                            vertical-align: middle;
                            margin-bottom: 2px;
                        }

                        .separator {
                            margin: 0 map-get($spacers, 1);
                            color: $gray-500;
                        }

                        .location {
                            margin-left: .1rem;
                            font-weight: $font-weight-medium;
                        }

                        .time {
                        }

                        .views {
                            color: $gray-400;
                        }
                    }
                }

                .actions {
                    margin-left: map-get($spacers, 2);
                    min-width: 110px;

                    @include media-breakpoint-down($breakpoint-mobile) {
                        & {
                            display: none;
                        }
                    }
                }

                &.size-sm {
                    .picture {
                        img {
                            width: $picture-size-sm;
                            height: $picture-size-sm;
                        }
                    }
                }
            }
        }
    }

    .weekend-separator {
        position: relative;
        margin: map-get($spacers, 4) 0;
        border-bottom: .1rem solid $gray-100;

        &::after {
            position: absolute;
            display: block;
            content: 'WKND';
            font-size: .7rem;
            color: $gray-400;
            top: -0.45rem;
            left: 0;
            right: 0;
            text-align: center;
        }
    }
}
