.ev-l-row {
  padding: 7px;
  margin: 0;
  color: #000;
  cursor: pointer;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);

  -webkit-transition: background 200ms ease;
  -moz-transition: background 200ms ease;
  -ms-transition: background 200ms ease;
  -o-transition: background 200ms ease;
  transition: background 200ms ease;
}

div.ev-l-row:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ev-l-row:hover,
.ev-l-row:focus {
  background: rgba(0, 0, 0, 0.1);
}

.ev-l-list-divider {
  border-bottom: 10px solid rgba(0, 0, 0, 0.05);
}

.ev-l-row .glyphicons {
  margin: 0;
  vertical-align: -2px;
}

.ev-l-row-thumb img {
  width: 74px;
  height: 74px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.gl-ev-l-past {
  opacity: 0.5;

  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

.gl-ev-l-past:hover,
.gl-ev-l-past:focus {
  opacity: 1;
}

.ev-l-small .ev-l-row-thumb img {
  width: 34px;
  height: 34px;
}

.ev-l-row .desktop-buttons {
  margin-right: 5px;
}

.ev-l-row a.btn {
  margin: 3px;
  padding: 0.3em;
}

.ev-l-row a.btn .glyphicons {
  margin: 0;
}

.ev-l-row-title {
  margin: 0;
  font-size: 106.3%;
  font-weight: 500;
  height: 1em;
  line-height: 1em;
  overflow: hidden;
}

.ev-l-row-title a {
  color: black;
}

.ev-l-row-setting {
  font-size: 95%;
  margin: 0.4em 0;
}

.ev-l-row-setting > span {
  margin-right: 0.4em;
}

.ev-l-row-setting > span.l-link {
  font-weight: 500;
}

.ev-l-row-setting > span:not(.l-link) {
  color: #757575;
}

.ev-l-row-setting .l-views {
  color: #BDBDBD !important;
}

.ev-l-premium {
  margin-left: -3px;
  background: #faecc7; /*f3e5a9*/
  border-left: 3px solid #b90a00;
  border-corner-shape: notch;
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.ev-l-row-info {
  margin-top: 4px;
}

.ev-l-small .ev-l-row-info {
  margin-top: 0;
}

.ev-l-row-info ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  font-size: 93.8%;
}

.ev-l-row-info ul li .glyphicons {
  margin-right: 0 !important;
  opacity: 0.5;
}

.ev-l-row-hidden {
  display: none;
}

.ev-l-sdbtn {
  display: block;
  padding: 3px 0;
  border-radius: 4px 4px 4px 4px;
  background-color: #F5F5F5;
}

.ev-l-sdbtn .badge {
  margin-left: 5px;
}

.ev-l-wknd-seperator {
  margin: 2em 0;
  width: auto;
  position: relative;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.ev-l-wknd-seperator::after {
  content: "WKND";
  font-size: 9px;
  color: rgba(0, 0, 0, 0.35);
  position: absolute;
  top: -5px;
  margin-left: -10px;
  left: 50%;
}

.ev-l-event-canceled {
  opacity: 0.7;
}

.ev-l-event-canceled .ev-l-row-title a {
  text-decoration: line-through;
}

.ev-l-row .label {
  padding: 0.15em 0.6em 0.1em;
}

.ev-l-row .mobile-buttons {
  position: relative;
}

.ev-l-row .mobile-buttons .glyphicons,
.ev-l-row .mobile-buttons .social {
  font-size: 18px;
}

.ev-l-row .mobile-buttons .glyphicons.default,
.ev-l-row .mobile-buttons .social.default {
  color: #c7c7cc;
}

.ev-l-row .mobile-buttons .glyphicons.success,
.ev-l-row .mobile-buttons .social.success {
  color: #85c075;
}

.ev-l-row .mobile-buttons .glyphicons.danger,
.ev-l-row .mobile-buttons .social.danger {
  color: #e8554e;
}

.ev-l-row .mobile-buttons .glyphicons.primary,
.ev-l-row .mobile-buttons .social.primary {
  color: #337ab7;
}

.ev-l-row .mobile-buttons .mobile-multiple-specials-available {
  position: absolute;
  top: -8px;
  right: 6px;
}

.ev-l-row .mobile-buttons .mobile-multiple-specials-available i {
  font-size: 10px;
}

/* Card View */
.ev-l-cardview .ev-l-row {
  padding: 0 7px 0 0;
}

.ev-l-cardview .ev-l-large .ev-l-row-thumb {
  background: black;
  position: relative;
  min-width: 180px;
}

.ev-l-cardview .ev-l-large .ev-l-row-thumb a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.ev-l-cardview .ev-l-large .ev-l-premium {
  padding-left: 0;
}

.ev-l-cardview .ev-l-large .ev-l-row-content {
  position: relative;
  margin-left: 140px;
  padding: 10px;
}

.ev-l-cardview .ev-l-large .ev-l-row-content .ev-l-row-content-inner {
  position: relative;
}

.ev-l-cardview .ev-l-large .ev-l-row-setting .l-location-address,
.ev-l-cardview .ev-l-large .ev-l-row-setting .l-time {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #ddd;
}

.ev-l-cardview .ev-l-large .ev-l-row-content .ev-l-row-content-inner .ev-l-text-preview h3 {
  margin-top: 10px;
  font-size: 114.3%;
  text-align: left;
}

.ev-l-cardview .ev-l-large .ev-l-row-content .ev-l-row-content-inner .ev-l-text-preview p {
  font-size: 92.9%;
  text-align: justify;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.ev-l-cardview .ev-l-large .ev-l-row-content .ev-l-row-content-inner .ev-l-text-preview p:last-child {
  margin-bottom: 0;
}

.ev-l-cardview .ev-l-large .ev-l-mobile-action-button {
  margin-top: 1em;
}

.ev-l-cardview .ev-l-large .ev-l-mobile-action-button a.btn {
  padding: 0.5em;
}

@media (max-width: 767px) {
  .j-helper-hiddentextoverflow {
    white-space: normal !important;
  }
  .ev-l-cardview .ev-l-row {
    padding: 0;
    border-bottom-width: 5px;
  }
  .ev-l-cardview .ev-l-large .ev-l-row-thumb {
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }
  .ev-l-cardview .ev-l-large .ev-l-row-thumb img {
    display: none;
  }
  .ev-l-cardview .ev-l-large .ev-l-row-content {
    margin-left: 0;
  }
  .ev-l-cardview .ev-l-large .ev-l-row-content .ev-l-row-content-inner .ev-l-text-preview {
    max-height: 110px;
    min-height: 30px;
    overflow: hidden;
  }
  .ev-l-cardview .ev-l-row .ev-l-row-content .ev-l-row-content-inner .ev-l-text-preview:after {
    position: absolute;
    display: block;
    content: ' ';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }
  .ev-l-cardview .ev-l-premium .ev-l-row-content .ev-l-row-content-inner .ev-l-text-preview:after {
    background: linear-gradient(to bottom, rgba(243, 229, 169, 0) 0%, rgba(250, 236, 199, 1) 100%);
  }
  .ev-l-cardview .ev-l-row:hover .ev-l-row-content .ev-l-row-content-inner .ev-l-text-preview:after,
  .ev-l-cardview .ev-l-row:focus .ev-l-row-content .ev-l-row-content-inner .ev-l-text-preview:after {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 100%);
  }
}

;@import "sass-embedded-legacy-load-done:2401";