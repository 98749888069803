@mixin columnsjs {
    $columnGutter: map-get($spacers, 3);

    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    column-gap: $columnGutter;

    @for $i from 1 through 2 {
        &[data-columns="#{$i}"] {
            @include media-breakpoint-up(lg) {
                grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
            }
        }
    }
}

.masonry-grid {
    @include columnsjs;
}

;@import "sass-embedded-legacy-load-done:2414";