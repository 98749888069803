/*
    This file includes browser hacks and switches
    to achieve specific behavior in some circumstances.

    This file works in tandem with "scripts/browser/hacks.js".
*/
:focus {
    outline: none !important;
}

::-moz-focus-inner {
    border: 0 !important;
}

img:-moz-broken {
    -moz-force-broken-image-icon: 1;
}
;@import "sass-embedded-legacy-load-done:2432";