.z-depth-0 {
    box-shadow: none !important;
}

@for $i from 1 through length($shadows) {
    .z-depth-#{$i} {
        @include createShadow(nth($shadows, $i));
    }
}

;@import "sass-embedded-legacy-load-done:2425";