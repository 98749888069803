@import "../../Assets/stylesheets/bootstrap/variables";

.e2956a7c5469f9dc-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content {
        position: relative;

        > div:not(.free-floating), > iframe {
            @include z-depth(1);
            @include transition;
            margin: 0 auto;

            &:hover,
            &:focus {
                @include z-depth(5);
            }
        }

        &.use-max-width {
            width: 100%;
            margin: 0;

            > div, > iframe {
                width: 100%;
                margin: 0;
            }
        }

        iframe {
            display: block;
            overflow: hidden;
            outline: none;
            border: none;
            margin: 0 auto;
            max-width: 100%;
        }

        .indicator {
            position: absolute;
            font-size: 8px;
            text-transform: uppercase;
            color: $gray-700;
            background: rgba(255, 255, 255, 0.8);
            padding: 0.05rem 0.2rem;
            right: 0;
            bottom: 0;
            border-top-left-radius: 0.3rem;
        }
    }
}
