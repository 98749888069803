$icon-sizes: (
    tiny: 8px,
    small: 12px,
    smaller: 16px,
    default: 24px,
    large: 32px,
    big: 48px,
);

@each $name, $value in $icon-sizes {
    .icon-#{$name} {
        font-size: $value;
        width: $value;
        height: $value;
    }
}

;@import "sass-embedded-legacy-load-done:2409";