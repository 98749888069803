@import "../../Assets/stylesheets/bootstrap/variables";

.beff1b4931862-component {
    $indicatorHeight: 14px;
    
    max-width: 100%;
    overflow: hidden;
    flex-direction: column;
    align-items: center;

    &.full-width {
        > div {
            flex: 1;
            width: 100%;

            .content {
                width: 100%;

                > * {
                    width: 100%;
                }
            }
        }
    }

    > div {
        position: relative;
        margin-bottom: $indicatorHeight;
    }


    .content {
    }

    iframe {
        display: block;
    }
    
    .mso-cls-wrapper {
        min-height: 0 !important;
    }
    
    ins {
        display: block !important;
    }

    .indicator {
        display: block;
        position: absolute;
        font-size: 8px;
        text-transform: uppercase;
        color: $gray-700;
        background: rgba(255, 255, 255, 0.8);
        border: 1px solid $gray-300;
        border-top-width: 0;
        padding: 0.05rem 0.2rem;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: $indicatorHeight * -1;
        height: $indicatorHeight;
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
    }

    a::after {
        display: none !important;
        content: initial !important;
    }
}

html.local {
    .adsbygoogle {
        background: $gray-200;
    }
}
