.antiscroll-wrap {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.antiscroll-scrollbar {
  background: gray;
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  -webkit-box-shadow: 0 0 1px #fff;
  -moz-box-shadow: 0 0 1px #fff;
  box-shadow: 0 0 1px #fff;
  position: absolute;
  opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  -webkit-transition: linear 300ms opacity;
  -moz-transition: linear 300ms opacity;
  -o-transition: linear 300ms opacity;
}

.antiscroll-scrollbar-shown {
  opacity: 1;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}

.antiscroll-scrollbar-horizontal {
  height: 7px;
  margin-left: 2px;
  bottom: 2px;
  left: 0;
}

.antiscroll-scrollbar-vertical {
  width: 7px;
  margin-top: 2px;
  right: 2px;
  top: 0;
}

.antiscroll-inner {
  overflow: scroll;
}

/** A bug in Chrome 25 on Lion requires each selector to have their own
    blocks. E.g. the following:

    .antiscroll-inner::-webkit-scrollbar, .antiscroll-inner::scrollbar {...}

    causes the width and height rules to be ignored by the browser resulting
    in both native and antiscroll scrollbars appearing at the same time.
 */
.antiscroll-inner::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.antiscroll-inner::scrollbar {
  width: 0;
  height: 0;
}

;@import "sass-embedded-legacy-load-done:2397";