@import "../../Assets/stylesheets/bootstrap/variables";

.breadcrumb-component {
    $icon-size: 18px;

    .breadcrumb {
        margin: 0;
        padding: 0;
        background: none;
        text-transform: uppercase;
        font-size: $font-size-small;
        align-items: center;

        .icon {
            font-size: $icon-size;
            line-height: $icon-size;
            margin-top: -1px;
        }

        .index-icon {
            line-height: $icon-size;
            margin-top: -1px;
            display: inline-block;

            svg {
                width: $icon-size;
                height: $icon-size;
            }
        }
    }
}
