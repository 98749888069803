.ribbon {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 60px;
    margin-bottom: -8px;
    z-index: 1;
    padding: 0.25rem 0.5rem 0.5rem 0.5rem;
    border-radius: 2px 2px 0 0;
    text-align: left;
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
    }

    &::before {
        height: 100%;
        width: 110%;
        transform-origin: bottom right;
        transform: rotate(1deg);
    }

    &::after {
        transform: rotate(-3deg);
        transform-origin: bottom left;
        height: 100%;
        width: 110%;
    }

    .content {
        position: relative;
        z-index: 1;
        font-size: 0.8rem;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }
    /*
        COLORS
    */
    &.ribbon-primary {
        color: $white;

        &::before {
            background: rgba($primary, 0.7);
        }

        &::after {
            background: $primary;
        }
    }

    &.ribbon-danger {
        color: $white;

        &::before {
            background: rgba($danger, 0.7);
        }

        &::after {
            background: $danger;
        }
    }
}

;@import "sass-embedded-legacy-load-done:2416";