.glyphicons {
  vertical-align: baseline !important;
}

i.livicon {
  min-width: 16px;
  min-height: 16px;
}

img.icon-alignment {
  margin-bottom: 0.25em;
}

.glyphicons:only-child {
  margin: 0 !important;
}

.livicon {
  display: inline-block;
  line-height: inherit;
  vertical-align: -3px;
  /*height: 18px!important;*/
}

.navbar .livicon {
  display: inline-block;
  line-height: inherit;
  vertical-align: middle;
  height: 16px !important;
  margin-top: -1px;
}

h1.glyphicons {
  padding-left: 35px !important;
}

h1.glyphicons:before {
  top: 9px !important;
  margin: 0 !important;
}

h1 .livicon {
  line-height: 42px !important;
  vertical-align: top;
}

h3 .livicon {
  line-height: 32px !important;
  vertical-align: top;
  margin-top: -4px;
}

h4 .livicon {
  line-height: 32px !important;
  vertical-align: top;
  margin-top: -4px;
}

;@import "sass-embedded-legacy-load-done:2376";