@import "../../Assets/stylesheets/bootstrap/variables";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~@glidejs/glide/dist/css/glide.core.min";
@import '~@glidejs/glide/dist/css/glide.theme.min';

.artist-list-component {
  $itemGap: map-get($spacers, 3);
  $regularItemsColumns: (xs: 2, sm: 4, md: 4, lg: 6, xl: 6);
  $highlightedItemsColumns: (xs: 1, sm: 1, md: 1, lg: 2, xl: 2);
  
  .tags {
    white-space: normal;

    span {
      margin-right: map-get($spacers, 1);
      margin-bottom: map-get($spacers, 1);
    }
  }
  
  .artist-card-list {
    display: grid;
    grid-column-gap: $itemGap;
    grid-row-gap: $itemGap;
    margin-bottom: $itemGap;
    
    @mixin grid-template($numOfColumns) {
      grid-template-columns: repeat($numOfColumns, 1fr);
    }

    @each $breakpoint, $numOfColumns in $regularItemsColumns {
      @if $breakpoint == 'xs' {
        @include media-breakpoint-down($breakpoint) {
          & {
            @include grid-template($numOfColumns);
          }
        }
      } @else {
        @include media-breakpoint-up($breakpoint) {
          & {
            @include grid-template($numOfColumns);
          }
        }
      }
    }
    
    .artist-card {
      margin: 0;
      grid-column: span 2;

      &.is-premium, &.is-top {
        grid-column: span 3;
      }
      
      .artist-item {
        .media-body {
          padding-right: map-get($spacers, 2);
        }
        
        .title, .text {
          margin-bottom: map-get($spacers, 2);
        }

        .title {
          font-size: 1rem;
        }

        .text {
          text-align: justify;
          hyphens: auto;
          font-size: .8rem;
        }
      }
    }
  }

  .artist-item {
    position: relative;
    padding: map-get($spacers, 2);
    transition: create-transition();

    &:not(:last-child) {
      border-bottom: 0.1rem solid $gray-100;
    }

    &:hover, &:focus {
      background-color: $hover-background-default;
    }

    &:active {
      background-color: $active-background-default;
    }

    img.thumbnail {
      margin-right: map-get($spacers, 2);
      border-radius: $card-border-radius;
      width: 42px;
      height: 42px;
      object-fit: contain;
    }

    .content {
      flex: 1;
      overflow: auto;

      .title, .metadata {
        margin-bottom: map-get($spacers, 1);
      }

      .title {
        a {
          color: inherit;
          font-weight: $font-weight-bold;
          text-decoration: none;
        }
      }
    }
  }

  .artist-preview {
    .glide__track {
      z-index: 0;

      .glide__slides {
        margin: 0;

        .glide__slide {
          position: relative;

          a {
            display: block;
          }

          img {
            position: relative;
            z-index: 0;
            display: block;
            object-fit: cover;
            object-position: center center;
            width: 100%;
            max-width: none !important;
            max-height: 400px;
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            pointer-events: none;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 30%;
            background-image: create-scrim-gradient();
          }

          .content {
            position: absolute;
            z-index: 1;
            left: 0;
            width: 100%;
            bottom: 0;
            padding: map-get($spacers, 3);
            padding-bottom: map-get($spacers, 4);
            color: $white;
          }
        }
      }
    }
  }
}
