@font-face {
  font-family: 'Material Icons';
  font-display: block;
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('./material-icons-outlined.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('./material-icons-outlined.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

;@import "sass-embedded-legacy-load-done:50";