.xcard {
    $card-background: $white;
    $card-header-border-color: $gray-50;

    @extend .card;
    @extend .z-depth-1;
    border: none;
    background: $card-background;

    &:not(.no-margin):not(:last-child) {
        margin-bottom: $spacer;
    }

    &.xcard-primary {
    }

    &.elevate-hover {
        @include transition;

        &:focus,
        &:hover,
        &.active {
            @include z-depth(5);
        }
    }

    &.xcard-highlight-brand {
        $border-width: 0.2rem;
        $inner-radius: $card-border-radius - 0.1rem;

        margin: $border-width;
        background-clip: padding-box;
        box-shadow: none !important;
        border-radius: $inner-radius;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            border-radius: $card-border-radius;
            background: $white;
        }

        &::after {
            @include transition;
            content: '';
            position: absolute;
            top: -$border-width;
            right: -$border-width;
            bottom: -$border-width;
            left: -$border-width;
            z-index: -2;
            border-radius: $card-border-radius;
            background: $brand-gradient;
            @include z-depth(1);
        }

        &.elevate-hover {
            &:focus,
            &:hover,
            &.active {
                &::after {
                    @include z-depth(5);
                }
            }
        }

        .xcard-img-top {
            img {
                border-top-left-radius: $inner-radius;
                border-top-right-radius: $inner-radius;
            }
        }
    }

    &.xcard-flat {
        box-shadow: none;
    }
    //
    // ORIENTATION
    //
    &.xcard-to-right {
        flex-direction: row;
    }
    //
    // IMAGE
    //
    .xcard-img-top {
        @extend .card-img-top;

        img {
            border-top-left-radius: $card-border-radius;
            border-top-right-radius: $card-border-radius;
        }
    }

    .xcard-img-left {
        @extend .card-img-top;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: $card-border-radius;
        border-bottom-left-radius: $card-border-radius;

        img {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: $card-border-radius;
            border-bottom-left-radius: $card-border-radius;
        }
    }
    //
    // HEADER
    //
    .xcard-header {
        @extend .card-header;
        background: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: .3rem solid $card-header-border-color;

        .title {
            flex: 1;
            font-size: 1rem;
            font-weight: $font-weight-medium;

            &, .subtitle {
                margin: 0;
                line-height: 1.4em;
            }

            a {
                color: $black;
            }

            a {
                display: block;
                text-decoration: none;
            }

            .subtitle {
                display: block;
                font-size: 1rem;
                font-weight: $font-weight-normal;
                color: $gray-600;
            }
        }
    }

    .xcard-body {
        @extend .card-body;

        &.no-spacing {
            padding: 0;
        }

        .xcard-title {
            @extend .card-title;
        }
    }

    .xcard-footer {
        @extend .card-footer;
        background: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: .3rem solid $card-header-border-color;
    }
}

;@import "sass-embedded-legacy-load-done:2399";