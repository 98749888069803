a:not(.btn):not(.no-target-indicator)[target="_blank"]::after {
    @include pseudoElIcon('open_in_new', inherit);
    display: inline-block;
    vertical-align: middle;
    margin-top: -1px;
    margin-left: map-get($spacers, 1);
}

a[id]:not([href]):empty {
    display: block;
    position: relative;
    visibility: hidden;
    top: -100px;
}

;@import "sass-embedded-legacy-load-done:2413";