.btn {
    text-transform: uppercase;
    transition: create-transition();

    &.btn-brand {
        color: $white;
        position: relative;
        background: $brand-gradient;
        border: none !important;
        z-index: 1;
        padding: ($btn-padding-y + $btn-border-width) ($btn-padding-x + $btn-border-width);

        &::before {
            border-radius: inherit;
            content: '';
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            background: $brand-gradient-light;
            transition: create-transition(opacity);
        }

        &:not(:disabled):hover,
        &:not(:disabled):focus {
            &::before {
                opacity: 1;
            }
        }

        &.btn-sm {
            padding: ($btn-padding-y-sm + $btn-border-width) ($btn-padding-x-sm + $btn-border-width);
        }
    }

    &.btn-flat {
        border: none;

        @each $color, $value in $theme-colors {
            &.btn-#{$color} {
                @extend .btn-outline-#{$color};
            }
        }
    }

    &.btn-raised:not(:disabled) {
        @include z-depth(1);

        &:focus,
        &:hover,
        &:active,
        &.active {
            @include z-depth(5);
        }
    }

    @each $color, $value in $theme-colors {
        &.btn-outline-#{$color} {
            border-width: .1rem;
        }
    }

    &.btn-icon,
    &.btn-icon-text {
        display: flex;
        align-items: center;

        .material-icons {
            font-size: 21px;
        }
    }

    &.btn-icon-text {
        .material-icons {
            margin-right: map-get($spacers, 1);
        }
    }
}

;@import "sass-embedded-legacy-load-done:2394";