body {
    scroll-behavior: smooth;

    &.no-scroll {
        overflow: hidden !important;
    }
}

.container, .container-fluid {
    min-width: 320px;
}

.layout-container {
    width: 100%;
}

.layout-sidebar {
    display: none;
}

.field-validation-valid {
    display: none;
}

.j-hidden {
    display: none;
}

.j-usehandcur {
    cursor: pointer !important;
}

.j-noborder {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.j-helper-hiddentextoverflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.j-helper-noborder-topleft {
    -webkit-border-top-left-radius: 0 !important;
    -moz-border-radius-topleft: 0 !important;
    border-top-left-radius: 0 !important;
}

.j-animate-default {
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

/* positioning */
.gl-center {
    text-align: center !important;
}

.gl-right {
    text-align: right !important;
}

/* Text formatting */
.gl-usecolumn-3col {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 2em;
    -moz-column-gap: 2em;
    column-gap: 2em;
}

.gl-usecolumn-3col p {
    text-align: justify;
}

.gl-usecolumn-block {
    display: inline-block;
}

.gl-story {
    padding: 7px 10px;
}

.gl-block-story p {
    text-align: justify;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

/* hacks */
.fb-comments,
.fb-comments span,
.fb-comments iframe,
.fb-like-box,
.fb-like-box span,
.fb-like-box iframe {
    max-width: 100% !important;
    width: 100% !important;
}

.profiler-results {
    opacity: 0.3;
}

.profiler-results:hover,
.profiler-results:focus {
    opacity: 1;
}

.gl-form-wrapper .form-panel {
    margin: 3px 0;
}

.lb-number {
    display: none !important;
}

.cc_banner-wrapper .cc_message {
    font-size: 14px !important;
}

.cc_banner-wrapper .cc_btn {
    font-size: 14px !important;
    background: #00b4dc !important;
    color: white !important;
    margin-right: 40px !important;
}

%hyphens {
    hyphens: auto;
}

@include media-breakpoint-up(lg) {
    .gl-app-2nd-level-nav .page-theme-wrapper {
        padding-top: $navbar-main-height;
    }
}

.cc-message {
    font-size: $font-size-smaller;
    line-height: 1.2em;
    text-align: justify;
    hyphens: auto;
}

// https://developers.google.com/recaptcha/docs/faq
.grecaptcha-badge {
    visibility: hidden;
}

;@import "sass-embedded-legacy-load-done:2374";