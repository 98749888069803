/* http://www.menucool.com */

#sliderFrame {
  position: relative;
  width: 320px;
  margin: 0 auto; /*center-aligned*/
}

#slider, #slider div.sliderInner {
  width: 320px; /* Must be the same size as the slider images */
}

#slider {
  position: relative;
  margin: 0 auto; /*center-aligned*/
  transform: translate3d(0, 0, 0);
  box-shadow: 0px 1px 5px #999999;
}

/* image wrapper */
#slider a.imgLink, #slider div.video {
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

/* Caption styles */
#slider div.mc-caption-bg, #slider div.mc-caption-bg2 {
  position: absolute;
  width: 100%;
  height: auto;
  padding: 10px 0; /* 10px will increase the height.*/
  left: 0px; /*if the caption needs to be aligned from right, specify by right instead of left. i.e. right:20px;*/
  bottom: 0px; /*if the caption needs to be aligned from top, specify by top instead of bottom. i.e. top:150px;*/
  z-index: 3;
  overflow: hidden;
  font-size: 0;
}

#slider div.mc-caption-bg {
  /* NOTE: Its opacity is to be set through the sliderOptions.captionOpacity setting in the js-image-slider.js file.*/
  background: Black; /* or any other colors such as White, or: background:none; */
}

#slider div.mc-caption-bg2 {
  background: none;
}

#slider div.mc-caption {
  font: bold 14px/20px Arial;
  color: #EEE;
  z-index: 4;
  text-align: center;
  background: none;
}

#slider div.mc-caption a {
  color: #FB0;
}

#slider div.mc-caption a:hover {
  color: #DA0;
}

/* ------ built-in navigation bullets wrapper ------*/
/* Note: check themes\2\js-image-slider.css to see how to hide nav bullets */
#slider div.navBulletsWrapper {
  text-align: center;
  background: none;
  position: absolute;
  width: 100%;
  z-index: 5;
  bottom: -30px;
}

/* each bullet */
#slider div.navBulletsWrapper div {
  width: 11px;
  height: 11px;
  font-size: 0;
  color: White; /*hide the index number in the bullet element*/
  background: black;
  display: inline-block;
  *display: inline;
  zoom: 1;
  overflow: hidden;
  cursor: pointer;
  margin: 0 6px; /* set distance between each bullet*/
}

#slider div.navBulletsWrapper div.active {
  background: #0F0;
}

/* --------- Others ------- */

#slider div.loading {
  width: 100%;
  height: 100%;
  background: transparent no-repeat 50% 50%;
  filter: alpha(opacity=60);
  opacity: 0.6;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
}

#slider img, #slider > b, #slider a > b {
  position: absolute;
  border: none;
  display: none;
}

#slider div.sliderInner {
  overflow: hidden;
  -webkit-transform: rotate(0.000001deg); /* fixed the Chrome not crop border-radius bug*/
  position: absolute;
  top: 0;
  left: 0;
}

#slider > a, #slider video, #slider audio {
  display: none;
}

;@import "sass-embedded-legacy-load-done:2421";