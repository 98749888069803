$max-ad-width: 300px;
$sidebar-spacing: 0;
$sidebar-width: $max-ad-width;

@include media-breakpoint-up($breakpoint-desktop) {
    body.has-sidebar-right {
        .layout-container {
            display: flex;
            flex-direction: row;
        }

        .layout-container,
        #root-nav {
            padding-right: $sidebar-width + $sidebar-spacing;
        }

        .page-body {
            .layout-container {
                padding-right: 0;
            }

            .container, .container-fluid {
                margin-right: $sidebar-spacing;
            }

            .layout-sidebar {
                display: block;
                flex: 0 0 $sidebar-width;
                margin-right: auto;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:2383";