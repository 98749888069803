.gl-pa-row {

}

.gl-pa-item {
  border: 1px solid #DCDCDC;
  padding: 5px;
  margin: 9px;
  float: left;
  background: #fff;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  -webkit-transition: background 600ms ease;
  -moz-transition: background 600ms ease;
  -ms-transition: background 600ms ease;
  -o-transition: background 600ms ease;
  transition: background 600ms ease;
}
.gl-pa-item:hover,
.gl-pa-item:focus {
  background: #f5f5f5;
}

.gl-pa-item.ungrouped {
  margin: 20px;
}
.gl-pa-item.ungrouped.small {
  margin: 0 0 10px 0;
}
.gl-pa-item.ungrouped.small:nth-child(odd) {
  margin: 0 10px 10px 0;
}

.gl-pa-item .gl-pa-item-thumb {
  position:relative;
}
.gl-pa-item .gl-pa-item-thumb img {
  border: 1px solid #ebebeb;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.gl-pa-item .gl-pa-item-thumb .event-title a {
  display: block;
  position:absolute;
  left: 1px;
  right: 1px;
  bottom: 1px;
  background: rgba(0,0,0, 0.5);
  color: #fff;
  padding: 1px 5px;
  font-size: 93.8%;

  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.gl-pa-item .image-count {
  display: block;
  position: absolute;
  right: 1px;
  top: 1px;
  background: rgba(0,0,0, 0.5);
  color: #fff;
  padding: 1px 5px;
  font-size: 70%;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.gl-pa-item .gl-pa-item-title {
  margin: 3px 1px 0 1px;
}
.gl-pa-item .gl-pa-item-title a {
  display: block;
  color: #000 !important;
  text-decoration: none !important;
}

.gl-pa-item .gl-pa-item-title .thin {
  /*font-family: SofiaProLight;*/
  font-weight: 400;
}
.gl-pa-item .gl-pa-item-title .thick {
  /*font-family: SofiaProMedium;*/
  font-weight: 700;
}
.gl-pa-item .gl-pa-item-title .seperator {
  border-right: 1px solid #DCDCDC;
  padding-right: 7px;
  margin-right: 3px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .gl-pa-row {
    margin: 0 70px;
  }
  /*.gl-pa-item .gl-pa-item-title a {
        width: 230px;
    }
    .gl-pa-item.small .gl-pa-item-title a {
        width: auto;
    }*/
}
@media (min-width: 1200px) {
  .gl-pa-row {
    margin: 0 40px;
  }
  /*.gl-pa-item .gl-pa-item-title a {
        width: 230px;
    }
    .gl-pa-item.small .gl-pa-item-title a {
        width: auto;
    }*/
}

;@import "sass-embedded-legacy-load-done:2395";