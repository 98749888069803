.article {
    %marginTop {
        margin-top: map-get($spacers, 4);
    }

    @for $i from 1 through 6 {
        h#{$i}:not(:first-child) {
            @extend %marginTop;
        }
    }

    p, ul li, ol li {
        text-align: justify;
        hyphens: auto;
    }
}

;@import "sass-embedded-legacy-load-done:2389";