@import "../../Assets/stylesheets/bootstrap/variables";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~@glidejs/glide/dist/css/glide.core.min";
@import '~@glidejs/glide/dist/css/glide.theme.min';

.event-brand-list-component {
    $itemGap: map-get($spacers, 3);
    $regularItemsColumns: (xs: 2, sm: 4, md: 4, lg: 6, xl: 6);
    $highlightedItemsColumns: (xs: 1, sm: 1, md: 1, lg: 2, xl: 2);

    .tags {
        white-space: normal;

        span {
            margin-right: map-get($spacers, 1);
            margin-bottom: map-get($spacers, 1);
        }
    }

    .event-brand-card-list {
        display: grid;
        grid-column-gap: $itemGap;
        grid-row-gap: $itemGap;
        margin-bottom: $itemGap;

        @mixin grid-template($numOfColumns) {
            grid-template-columns: repeat($numOfColumns, 1fr);
        }

        @each $breakpoint, $numOfColumns in $regularItemsColumns {
            @if $breakpoint == 'xs' {
                @include media-breakpoint-down($breakpoint) {
                    & {
                        @include grid-template($numOfColumns);
                    }
                }
            }
            @else {
                @include media-breakpoint-up($breakpoint) {
                    & {
                        @include grid-template($numOfColumns);
                    }
                }
            }
        }

        .event-brand-card {
            margin: 0;
            grid-column: span 2;

            &.is-premium, &.is-top {
                grid-column: span 3;
            }

            .event-brand-item {
                .media-body {
                    padding-right: map-get($spacers, 2);
                }

                .title, .text {
                    margin-bottom: map-get($spacers, 2);
                }

                .title {
                    font-size: 1rem;
                }

                .text {
                    text-align: justify;
                    hyphens: auto;
                    font-size: .8rem;
                }
            }
        }
    }

    .event-brand-item {
        position: relative;
        padding: map-get($spacers, 2);
        transition: create-transition();

        &:not(:last-child) {
            border-bottom: 0.1rem solid $gray-100;
        }

        &:hover, &:focus {
            background-color: $hover-background-default;
        }

        &:active {
            background-color: $active-background-default;
        }

        img.thumbnail {
            margin-right: map-get($spacers, 2);
            border-radius: $card-border-radius;
            width: 72px;
            height: 72px;
            object-fit: contain;
        }

        .content {
            flex: 1;
            overflow: auto;

            .title, .metadata {
                margin-bottom: map-get($spacers, 1);
            }

            .title {
                a {
                    color: inherit;
                    font-weight: $font-weight-bold;
                    text-decoration: none;
                }
            }

            .text {
                text-align: left;
            }
        }
    }
}
