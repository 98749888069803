.page-body {
    padding: map-get($spacers, 4) 0;

    &.has-ads {
        display: flex;
        flex-direction: row;

        &.has-container {
            .container {
                &.container-ads {
                    margin-right: map-get($spacers, 2);
                }
            }

            .container-ads-right {
                margin-right: auto;
            }
        }

        &:not(.has-container) {
        }

        .container-ads-right {
            width: 300px;
        }
    }
}

#breadcrumbs {
    margin-top: map-get($spacers, 1);
}

@include media-breakpoint-up($breakpoint-desktop) {
    .page-body {
        padding: map-get($spacers, 3) 0;
    }
}

;@import "sass-embedded-legacy-load-done:2379";