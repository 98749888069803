html.no-js {
    .no-js-warning {
        display: flex !important;
        position: fixed;
        pointer-events: none;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        top: 0;
        right: 0;
        left: 0;
        z-index: 9999999;

        .inner {
            pointer-events: all;
            background: rgba($danger, 0.85);
            color: $white;
            text-align: center;
            padding: map-get($spacers, 2);

            a {
                color: $white;
                font-weight: $font-weight-bold;

                &:hover,
                &:focus {
                    color: $gray-100;
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:2386";