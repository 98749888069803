@import "~bootstrap/scss/mixins/breakpoints";

&.style-default {
    // All variables for styling
    $border-bottom-width: 2px;
    $seperator-width: 1px;
    $item-spacing: .7rem;
    $item-padding: .8rem;
    $item-icon-size: 1.4rem;
    $item-color: $white;
    $item-active-color: $white;
    $navbar-bottom-padding: 1rem;
    $reveal-anim-left-inset: -2rem;
    $navbar-bg-color-transparent: rgba($black, 0);
    $navbar-bg-color: rgba($black, .85);

    background-color: $navbar-bg-color;

    @keyframes dropdown-menu-in {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
    // Global styles for the bar element
    @mixin navbar-nocollapse-styles {
        .nav-divider {
            margin-top: $item-padding;
            margin-bottom: $item-padding;
            border-left: $seperator-width solid $white;
        }

        .nav-item {
            .nav-link {
                display: flex;
                align-items: center;
                position: relative;
                font-weight: $font-weight-normal;
                color: $item-color;
                padding: $item-padding 0;
                margin: 0 $item-spacing;
                white-space: nowrap;
                text-overflow: ellipsis;
                @include transition(color $transition-duration-default $timing-func-deceleration);

                &.icon {
                    padding: $item-padding/3 0;
                }

                &:not(.icon)::before {
                    content: '';
                    position: absolute;
                    bottom: $border-bottom-width * -1;
                    left: 0;
                    width: 100%;
                    height: $border-bottom-width;
                    background: $white;
                    transform: scale3d(0, 1, 1);
                    @include transition;
                }

                .icon {
                    font-size: $item-icon-size;
                    width: $item-icon-size;
                    height: $item-icon-size;
                }
            }

            &:focus,
            &:hover,
            &:active,
            &.active {
                .nav-link {
                    font-weight: $font-weight-normal;
                    padding-left: 0;
                    padding-right: 0;
                    color: $item-active-color;

                    &:not(.icon)::before {
                        background: $item-active-color;
                        transform: scale3d(1, 1, 1);
                    }
                }

                &::after {
                    opacity: 1;
                }
            }

            &.dropdown {
                .dropdown-toggle {
                    display: flex;
                    align-items: center;

                    &::after {
                        $size: 1em;
                        @include pseudoElIcon('keyboard_arrow_down', $size, true);
                        border: none !important;
                        width: $size !important;
                        height: $size !important;
                    }
                }

                .dropdown-menu {
                    background: $black;
                    border: none;
                    position: absolute !important;
                    border-radius: 0;
                    border-bottom-left-radius: .2rem;
                    border-bottom-right-radius: .2rem;
                    box-shadow: 0 .1rem 0rem rgba(0, 0, 0, 0);
                    @include transition;

                    &.dropdown-menu-right {
                        right: 0;
                        left: auto;
                    }

                    &.show {
                        box-shadow: 0 .2rem 2rem rgba(0, 0, 0, 0.3);
                    }

                    &:focus,
                    &:hover {
                        box-shadow: 0 .2rem 5rem rgba(0, 0, 0, 0.5);
                    }

                    .dropdown-header {
                        color: $gray-400;
                        font-size: .7rem;
                        border-left: $border-bottom-width solid rgba($black, 0)
                    }

                    .dropdown-divider {
                        border-top: $seperator-width solid $white;
                        margin: .5rem 1.6rem;
                    }

                    .dropdown-item {
                        color: $item-color;
                        border-left: $border-bottom-width solid rgba($white, 0);
                        background: none !important;
                        @include transition;

                        &:focus,
                        &:hover,
                        &.active {
                            color: $item-active-color;
                            border-left-color: $item-active-color;
                        }

                        @include media-breakpoint-down($breakpoint-mobile) {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }

    .navbar-brand {
        $brand-padding: .3rem;
        padding-top: $brand-padding;
        padding-bottom: $brand-padding;
    }

    ul.navbar-nav.non-collapsible {
        @include navbar-nocollapse-styles;
        flex-direction: row !important;
        margin-left: 0 !important;
        opacity: 1;
    }

    @include media-breakpoint-down($breakpoint-mobile) {
        align-items: flex-start;

        .navbar-brand {
            margin-left: 0 !important;
        }

        .navbar-collapse {
            position: fixed;
            overflow-y: hidden;
            top: 0;
            left: -100vw;
            width: 100%;
            background: $black;
            -webkit-overflow-scrolling: touch;
            opacity: 0;
            padding-left: 1rem;
            padding-right: 1rem;
            z-index: 1;
            @include transition;

            ul.navbar-nav {
                $anim-delay-init: .1s;
                $anim-delay-item: .02s;
                margin: 1rem 0;

                > li {
                    position: relative;
                    opacity: 0;
                    left: $reveal-anim-left-inset;
                    animation-duration: .3s;
                    animation-fill-mode: forwards;
                    animation-timing-function: $timing-func-standard;

                    @for $i from 1 through 30 {
                        &:nth-child(#{$i}) {
                            animation-delay: $anim-delay-init + $anim-delay-item * ($i - 1);
                        }
                    }
                }

                .nav-item {
                    .nav-link {
                        font-size: $font-size-big;
                        padding: 0 $item-padding;
                        margin: $item-spacing 0;
                        color: $item-color;
                        border-left: $border-bottom-width solid rgba($white, 0);
                    }

                    &:focus,
                    &:hover,
                    &:active,
                    &.active {
                        .nav-link {
                            font-weight: $font-weight-bold;
                            color: $item-active-color;
                            border-left-color: $item-active-color;
                        }
                    }
                }
            }
        }

        .nav-divider {
            margin: $item-padding;
            margin-left: calc(#{$item-padding} + #{$seperator-width + 2px});
            border-bottom: $seperator-width solid $white;
            max-width: 2rem;
        }

        .navbar-brand {
            margin: 0 auto;
        }

        &.expanded {
            background: $black;

            ul.navbar-nav.non-collapsible {
            }

            .navbar-collapse {
                overflow-y: auto;
                opacity: 1;
                left: 0vw;
                padding-bottom: $navbar-bottom-padding;

                ul.navbar-nav {
                    > li {
                        animation-name: navbar-item-in;
                    }
                }
            }

            @keyframes navbar-item-in {
                from {
                    opacity: 0;
                    left: $reveal-anim-left-inset;
                }

                to {
                    opacity: 1;
                    left: 0rem;
                }
            }
        }
    }

    @include media-breakpoint-up($breakpoint-desktop) {
        &.transparent {
            @include transition(background-color $transition-duration-default $timing-func-deceleration);
            background-color: $navbar-bg-color-transparent;
            background-image: create-scrim-gradient(to bottom);
        }

        &[data-aos="main-menu-bg"].transparent {
            background-color: $navbar-bg-color-transparent;

            &.aos-animate {
                background-color: $navbar-bg-color;
            }
        }

        .navbar-collapse {
            flex-direction: column;
            align-items: stretch;
            height: auto !important;
            top: 0 !important;

            ul.navbar-nav {
                @include navbar-nocollapse-styles;
                justify-content: space-around;
            }
        }

        ul.navbar-nav.non-collapsible {
            &.last-item {
                padding-left: $item-spacing * 2;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:2218";