@import "../../Assets/stylesheets/bootstrap/variables";

.location-list-component {
  $itemGap: map-get($spacers, 3);
  $regularItemsColumns: (xs: 1, sm: 2, md: 2, lg: 3, xl: 3);
  $highlightedItemsColumns: (xs: 1, sm: 1, md: 1, lg: 2, xl: 2);

  .items-group {
    margin-bottom: map-get($spacers, 5);

    .items-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: map-get($spacers, 2);

      .group-title {
        margin: 0;
      }

      .badge {
        margin-left: auto;
      }
    }

    .items-footer {
    }

    .items-wrapper {
      display: grid;
      grid-column-gap: $itemGap;
      grid-row-gap: $itemGap;
      margin-bottom: $itemGap;

      @mixin grid-template($numOfColumns) {
        grid-template-columns: repeat($numOfColumns, 1fr);
      }

      @each $breakpoint, $numOfColumns in $regularItemsColumns {
        @if $breakpoint == 'xs' {
          @include media-breakpoint-down($breakpoint) {
            & {
              @include grid-template($numOfColumns);
            }
          }
        } @else {
          @include media-breakpoint-up($breakpoint) {
            & {
              @include grid-template($numOfColumns);
            }
          }
        }
      }

      &.big-items {
        @each $breakpoint, $numOfColumns in $highlightedItemsColumns {
          @if $breakpoint == 'xs' {
            @include media-breakpoint-down($breakpoint) {
              & {
                @include grid-template($numOfColumns);
              }
            }
          } @else {
            @include media-breakpoint-up($breakpoint) {
              & {
                @include grid-template($numOfColumns);
              }
            }
          }
        }
      }
    }
  }

  .item {
    position: relative;
    color: inherit;
    text-decoration: none;
    margin: 0;

    .item-image {
      display: flex;
      flex: 0 0 16.6%;
      padding: map-get($spacers, 1);
      align-items: flex-start;

      img {
        object-fit: cover;
        border-radius: $card-border-radius;
      }
    }

    .card-title, .card-text {
      margin-bottom: map-get($spacers, 2);
    }

    .card-title {
      a {
        color: inherit;
        text-decoration: none;
      }
    }

    &.is-highlighted {
      &.is-premium {
      }

      .item-image {
        flex: 0 0 25%;
        align-items: flex-start;
      }

      .tags {
        margin-bottom: map-get($spacers, 1);
      }
    }

    &:not(.is-hightlighted) {
      .xcard-body {
        padding-top: map-get($spacers, 2);
        padding-bottom: map-get($spacers, 2);
      }
    }

    .xcard-body {
      display: flex;
      flex-direction: column;

      .card-title {
        font-size: 1rem;
      }

      .card-text {
        text-align: justify;
        hyphens: auto;
        font-size: .8rem;
      }

      .tags {
        margin-top: auto;
        z-index: 2;
      }
    }
  }
}
