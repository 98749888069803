@import "../../Assets/stylesheets/bootstrap/variables";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~@glidejs/glide/dist/css/glide.core.min";
@import '~@glidejs/glide/dist/css/glide.theme.min';

.carousel-component {
    .glide__track {
        z-index: 0;

        .glide__slides {
            margin: 0;

            .glide__slide {
                //visibility: hidden;

                img {
                    margin: 0 auto;
                    max-width: none;
                    width: 100%;

                    &:not(:first-child) {
                        display: none;
                    }
                }

                .use-max-height {
                    position: relative;
                    display: block;

                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 30%;
                        background-image: create-scrim-gradient();
                    }

                    img {
                        object-fit: cover;
                        object-position: top center;
                    }
                }
                // Current active element
                &.glide__slide--active {
                    //visibility: visible;
                }
            }

            .glide__bullets {

                &.glide__bullet--active {
                }
            }
        }
    }

    &.is-moving {
        .glide__slide {
            // Element left to the current active
            & + &.glide__slide--active {
                //visibility: visible;
            }

            &.glide__slide--active + & {
                //visibility: visible;
            }
        }
    }
}
