@import "../../Assets/stylesheets/bootstrap/variables";
@import "../../Assets/stylesheets/helper/backdrop";
@import "~bootstrap/scss/mixins/breakpoints";

.filter-bar-component {
    margin-top: map-get($spacers, 4);

    .wrapper {
        color: $white;
        @include backdrop-blur-default;

        .groups {
            padding-bottom: map-get($spacers, 2);

            .group {
                &:not(:first-child) {
                    margin-top: map-get($spacers, 3);
                }

                .title {
                    $titleColor: $gray-400;

                    color: $titleColor;
                    text-transform: uppercase;
                    font-size: .8rem;
                    text-align: center;
                    overflow: hidden;
                    margin-bottom: map-get($spacers, 2);

                    span {
                        $linePadding: 3rem;

                        display: inline-block;
                        position: relative;

                        &::before, &::after {
                            content: '';
                            position: absolute;
                            height: 3px;
                            background: rgba($titleColor, 0.3);
                            top: 50%;
                            width: 1000px;
                        }

                        &::before {
                            right: 100%;
                            margin-right: $linePadding;
                        }

                        &::after {
                            left: 100%;
                            margin-left: $linePadding;
                        }
                    }
                }

                .options {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;

                    .option {
                        margin-right: map-get($spacers, 2);
                        margin-bottom: map-get($spacers, 3);
                    }
                }
            }
        }

        .toggle {
            padding: map-get($spacers, 2);
        }
    }
}
