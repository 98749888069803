@import "../../Assets/stylesheets/bootstrap/variables";

.search-overlay-component {
    $background-color: $gray-100;
    $border-width: 0.15rem;

    .search-container {
        display: flex;
        flex-direction: column;
        position: fixed;
        overflow-x: hidden;
        overflow-y: hidden;
        background: $background-color;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        z-index: 100000;
        padding-top: 0;
        padding-bottom: 0;
        // Position
        width: 100%;
        height: 0;
        top: 0;
        left: 0;
        // Animation
        transform-origin: 100% 0;
        transition-property: height, padding;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0.7,0,0.3,1);

        @include media-breakpoint-up($breakpoint-desktop) {
            & {
                padding-bottom: 0 !important;
            }
        }

        .ui-component {
            $anim-delay-init: 0.5s;
            $anim-delay-item: 0.15s;

            opacity: 0;
            transition: create-transition(opacity);

            @for $i from 1 through 10 {
                &.index-#{$i} {
                    transition-delay: $anim-delay-init + $anim-delay-item * ($i - 1);
                }
            }
        }

        &.show {
            height: 100vh;
            overflow-y: scroll;
            padding-bottom: 150px;

            .ui-component {
                opacity: 1;
            }
        }

        .header {
            padding: $navbar-padding-y 0;
            margin-bottom: map-get($spacers, 4);
            background: $black;

            .container {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .close-handle {
                $size: 42px;
                padding: 0;
                margin: 0 0 0 auto;
                display: block;
                border: none;
                background: none;

                .icon {
                    display: block;
                    color: $white;
                    font-size: $size;
                    transition: create-transition(color);
                }

                &:hover,
                &:focus {
                    .icon {
                        color: $gray-200;
                    }
                }
            }
        }

        .search-input {
            $font-size-start: 20px;
            $font-size-end: 90px;
            $default-color: $gray-300;

            input {
                @include fluid-type(font-size, 320px, 1366px, $font-size-start, $font-size-end);
                display: block;
                width: 100%;
                border: none;
                background: none;
                padding: 0;
                font-weight: bold;
                line-height: 1em;

                &::placeholder {
                    color: $default-color
                }
            }

            button {
                display: block;
                border: none;
                background: none;

                .icon {
                    @include fluid-type(font-size, 320px, 1366px, $font-size-start, $font-size-end);
                    color: $default-color;
                }
            }
        }

        .progress {
            position: relative;
            height: 3px;
            opacity: 0;
            margin-bottom: map-get($spacers, 3);
            border-radius: 0;
            transition: create-transition(opacity);

            &::before, &::after {
                position: absolute;
                display: block;
                content: '';
                top: 0;
                width: 3rem;
                height: 100%;
            }

            &::before {
                left: 0;
                background-image: linear-gradient(to right,rgba($background-color,1),rgba($background-color,0))
            }

            &::after {
                right: 0;
                background-image: linear-gradient(to left,rgba($background-color,1),rgba($background-color,0))
            }

            &.is-loading {
                opacity: 1;
            }

            .progress-bar {
                width: 100%;
                background-image: linear-gradient(90deg,rgba($background-color,1) 50%,transparent 50%);
                //background-size: 2rem 2rem;
            }
        }

        .search-results {
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up($breakpoint-desktop) {
                & {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
                    column-gap: map-get($spacers, 3);
                }

                .search-result-group {
                    flex: 1 1 0;
                }
            }

            .search-result-group {
                display: flex;
                flex-direction: column;
                margin-bottom: map-get($spacers, 4);

                .group-title {
                    $spacing: map-get($spacers, 2);

                    font-weight: $font-weight-bold;
                    font-size: $font-size-big;
                    padding-bottom: $spacing;
                    margin-bottom: $spacing;
                    border-bottom: $border-width solid $black;
                }

                .items {
                    $spacing-bottom: 2;

                    .item {
                        position: relative;
                        padding-bottom: map-get($spacers, $spacing-bottom);
                        transition: create-transition(background-color);
                        background-color: rgba($black, 0);

                        &:not(:last-child) {
                            margin-bottom: map-get($spacers, $spacing-bottom);
                        }

                        &:hover,
                        &:focus {
                            background-color: rgba($black, 0.05);
                        }

                        a {
                            text-decoration: none !important;
                        }

                        img, .img {
                            width: 32px;
                            height: 32px;
                            border-radius: $card-border-radius;
                            background: $gray-300;
                            margin-top: 0.15rem;
                        }

                        .details {
                            display: flex;
                            align-items: center;
                            font-size: $font-size-small;
                        }

                        .tags {
                            & > span:not(.badge):first-child {
                                padding-left: 0;
                            }
                        }

                        .seperator {
                            display: block;
                            width: 0.15rem;
                            height: 0.15rem;
                            border-radius: 50%;
                            background: $black;
                            margin: 0 map-get($spacers, 2);
                        }
                    }
                }

                .paging {
                    margin-top: auto;
                    border-top: 1px solid $gray-200;

                    .pagination {
                        align-items: center;

                        .page-item {
                            .page-link {
                                border: none;
                                box-shadow: none !important;
                                border-radius: 0;
                                outline: none;
                                background: none;
                                color: $black;
                                border-bottom: $border-width solid transparent;
                                background-color: rgba($black, 0);
                                transition: create-transition(background-color);

                                .icon {
                                    display: block;
                                }

                                &:hover, &:focus {
                                    background-color: rgba($black, 0.05);
                                }
                            }

                            &.active {
                                .page-link {
                                    font-weight: $font-weight-bold;
                                    border-color: $black;
                                }
                            }

                            &.disabled {
                                .icon {
                                    color: $gray-400;
                                }
                            }
                        }
                    }
                }
            }
        }

        .empty-search-result {
            margin-top: map-get($spacers, 3);
            font-size: $font-size-large;
            color: $gray-500;
        }

        .search-suggestions {
            margin-top: map-get($spacers, 5);

            ul {
                list-style-type: none;
                margin: 0;
                padding-left: map-get($spacers, 2);

                li {
                    display: flex;
                    align-items: center;
                    margin-bottom: map-get($spacers, 2);
                    color: $gray-500;

                    &::before {
                        @include pseudoElIcon('format_quote');
                        margin-right: 0.5rem;
                        color: $gray-300;
                    }
                }
            }
        }

        .search-error {
            margin-top: map-get($spacers, 3);
            color: $danger;
            font-size: $font-size-large;
            font-weight: $font-weight-bold;
        }
    }
}
