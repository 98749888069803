footer {
    $footer-bg-color: $white;
    $footer-border-color: $gray-100;
    $footer-border-width: 0.1rem;

    @extend .fixed-bottom;
    z-index: 900;
    background: $footer-bg-color;
    border-top: $footer-border-width solid $footer-border-color;

    .container {
        padding-top: map-get($spacers, 1);
        padding-bottom: map-get($spacers, 1);

        .gl-fb-item-marker {
            display: inline-block !important;
        }

        .nav {
            .nav-item {
                .nav-link {
                    padding: 0.2rem 0.3rem;
                    font-size: 0.8rem;
                }
            }
        }
    }

    @include media-breakpoint-down($breakpoint-mobile) {
        .e2956a7c5469f9dc-component {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: map-get($spacers, 1);
        }
    }
}

;@import "sass-embedded-legacy-load-done:2384";