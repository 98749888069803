@import "../../Assets/stylesheets/bootstrap/variables";

.tab-menu-component {
    $overflow-indicator-icon-spacing: 1px;

    position: relative;

    .container {
        padding: 0;

        .nav {
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            -webkit-overflow-scrolling: touch;
            margin: 0 auto;

            .nav-item {

                &:first-child {
                    margin-left: auto;
                }

                &:last-child {
                    margin-right: auto;
                }

                &.spacing-start, &.spacing-end {
                    flex: 0 0 $grid-gutter-width/2;
                }

                .nav-link {
                    white-space: nowrap;
                }
            }
        }
    }

    %overflow-indicator {
        display: flex;
        align-items: center;
        position: absolute;
        content: '';
        width: $grid-gutter-width * 1.75;
        top: 0;
        bottom: 0;
        opacity: 0;
        pointer-events: none;
        transition: create-transition(opacity)
    }

    .start-overflow-indicator {
        @extend %overflow-indicator;
        left: 0;

        &.show {
            opacity: 1;
        }

        .icon {
            margin-left: $overflow-indicator-icon-spacing;
        }
    }

    .end-overflow-indicator {
        @extend %overflow-indicator;
        right: 0;

        &.show {
            opacity: 1;
        }

        .icon {
            margin-right: $overflow-indicator-icon-spacing;
        }
    }

    @import "styles/default";
}
