@import "../../Assets/stylesheets/bootstrap/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.navbar-component {
    .nav {
        align-items: stretch;

        .nav-item {
            display: flex;
            flex-direction: row;
            justify-content: center;

            .nav-link {
                display: flex;
                flex: 1;
                justify-content: center;
                align-content: center;

                h1, h2, h3, h4, h5, h6 {
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                    letter-spacing: inherit;
                    text-transform: inherit;
                    padding: 0;
                    margin: 0;
                }
            }

            &.dropdown {
                .dropdown-toggle {
                    justify-content: center;
                }
            }
        }
    }

    @include media-breakpoint-down($breakpoint-mobile) {
        .nav {
            .nav-item {
                .nav-link {
                    font-size: .8rem;
                }
            }
        }
    }

    @include media-breakpoint-up($breakpoint-desktop) {
        margin: 0 auto;
    }

    @import "styles/material";
}
