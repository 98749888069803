@import "../../Assets/stylesheets/bootstrap/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.partyphoto-album-list-component {
    $item-spacing: 2px; //map-get($spacers, 1);
    .partyphoto-album-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: -$item-spacing;
    }

    .date-grouped-partyphoto-album-list {
        .items-group {
            margin-bottom: map-get($spacers, 5);

            .items-header {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: map-get($spacers, 2);

                .group-title {
                    margin: 0;
                }

                .badge {
                    margin-left: auto;
                }
            }

            .partyphoto-album-item {
                margin-bottom: map-get($spacers, 3);
            }
        }
    }

    .partyphoto-album-item {
        flex: 1;
        min-width: 250px;
        max-width: 400px;
        margin: $item-spacing;
        transition: create-transition(box-shadow);

        &:hover, &:focus {
            @include z-depth(5);
        }

        %shape {
            position: absolute;
            width: 100%;
            left: 0;
        }

        .stretched-link::after {
            z-index: 3;
        }

        img {
            @extend %shape;
            top: 0;
            height: 100%;
            object-fit: cover;
            z-index: 0;

            &.active {
                z-index: 1;
            }

            &:not(.active) {
                visibility: hidden !important;
            }
        }

        .facts {
            @extend %shape;
            bottom: 0;
            z-index: 2;

            .event-title {
                background: rgba($black, 0.8);
                color: $white;
                font-size: $font-size-small;
                padding: map-get($spacers, 1) map-get($spacers, 2);
            }

            .metadata {
                background: rgba($white, 0.95);
                padding: map-get($spacers, 1) map-get($spacers, 2);
            }
        }
    }
}
