.badge {
    padding-top: 0.3em;
    padding-bottom: 0.2em;
}

.badge-disabled {
    color: $gray-400;
}

.badge-brand {
    color: $white;
    background: $brand-gradient;
}

.badge-hashtag {
    @extend .badge;
    @extend .badge-pill;

    &:not(.tag-secondary)::before {
        display: inline;
        content: '#';
    }

    &.tag-secondary {
        color: $gray-600;
        font-weight: $font-weight-normal;
    }
}

a.badge-hashtag {
    color: inherit;
    text-decoration: none !important;
    transition: create-transition(background), create-transition(color);

    &:hover, &:focus {
        background: rgba(0, 0, 0, 0.1);
    }

    &.tag-highlight {
        color: $black;
        background: $gray-200;

        &:hover, &:focus {
            color: $white;
            background: $gray-500;
        }
    }

    &:not([href]) {
        color: $danger !important;
    }
}

%badge-icon {
    @extend .badge;
    @extend .badge-pill;

    &::before {
        @include pseudoElIcon('headset', 12px);
        display: inline-block;
        vertical-align: bottom;
        margin-top: 1px;
        margin-right: 1px;
    }
}

.badge-music {
    @extend %badge-icon;
}

;@import "sass-embedded-legacy-load-done:2391";