.navbar-has-2nd-level {
  min-height: 42px;
}

.navbar-2nd-level {
    display: none;
}

.navbar-has-2nd-level .navbar-inner {
    box-shadow: none !important;
}

.navbar-has-2nd-level a.navbar-brand {
  height: 42px;
  padding-top: 3px;
}

.navbar-has-2nd-level a.navbar-brand img {
  height: 36px;
  width: auto;
}

.navbar-has-2nd-level ul.navbar-nav > li > a {
  padding: 11px 12px;
}

.navbar-has-2nd-level ul.navbar-nav .divider-vertical {
  height: 42px;
}

.navbar-has-2nd-level .navbar-form {
  margin: 6px;
}

.navbar-has-2nd-level .form-control {
  height: auto;
  padding: 4px 12px;
}

.navbar-has-2nd-level .navbar-toggle {
  margin-top: 5px;
  margin-bottom: 5px;
}

.navbar-2nd-level {
  z-index: 9995;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-top: 42px;
  min-height: 36px;
  height: 36px;
}

.navbar-2nd-level::-webkit-scrollbar {
  display: none;
}

.navbar-2nd-level .navbar-inner {
  box-shadow: none !important;
}

.navbar-2nd-level ul.navbar-nav {
  margin: 0 !important;
}

.navbar-2nd-level ul.navbar-nav > li.active {
  font-weight: 600;
}

.navbar-2nd-level ul.navbar-nav > li > a {
  color: black;
  padding: 8px 10px;
}

.navbar-2nd-level ul.navbar-nav .divider-vertical {
  height: 36px;
  border-left-color: rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  .navbar-2nd-level {
    margin-top: 0;
    margin-bottom: 5px;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    text-align: center;
  }

  .navbar-2nd-level ul.navbar-nav,
  .navbar-2nd-level ul.navbar-nav > li,
  .navbar-2nd-level ul.navbar-nav .divider-vertical {
    display: inline-block;
    white-space: nowrap;
  }
}

;@import "sass-embedded-legacy-load-done:2418";