@import "~bootstrap/scss/mixins/breakpoints";

&.style-material {
    // All variables for styling
    $border-bottom-width: 2px;
    $seperator-width: 1px;
    $item-spacing: .7rem;
    $item-padding: .5rem;
    $item-icon-size: 1.4rem;
    $item-color: $white;
    $item-active-color: $black;
    $item-active-bg-color: $white;
    $divider-color: $gray-200;
    $divider-width: 2px;
    $navbar-bg-color: rgba($black, .5);
    $navbar-bottom-padding: 1rem;
    $navbar-border-radius: 100px;
    $reveal-anim-left-inset: -2rem;

    @keyframes dropdown-menu-in {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    nav {
        background: $navbar-bg-color;
        border-radius: $navbar-border-radius;
        padding: 3px; // Used to be .2rem but theres a bug on Chrome setting a larger bottom padding, so theres the pixel value preventing this
        .nav {
            .nav-divider {
                display: none;
            }

            .nav-item {
                cursor: pointer;
                border-radius: 99px;
                background-color: rgba($item-active-bg-color, 0);
                @include transition;

                .nav-link {
                    padding: .2rem .75rem;
                    color: $item-color;
                    font-weight: $font-weight-medium;
                    line-height: $item-icon-size + .2rem;

                    &.icon {
                        line-height: $item-icon-size;
                    }

                    .icon {
                        font-size: $item-icon-size;
                        width: $item-icon-size;
                        height: $item-icon-size;
                        margin-top: .1rem;
                    }
                }

                &:focus,
                &:hover,
                &:active,
                &.active {
                    background-color: $item-active-bg-color;

                    .nav-link {
                        color: $item-active-color;
                    }
                }

                &.dropdown {
                    .dropdown-toggle {
                        display: flex;
                        align-items: center;

                        &::after {
                            $size: 1em;
                            @include pseudoElIcon('keyboard_arrow_down', $size, true);
                            border: none;
                            width: $size;
                            height: $size;
                            margin-top: 1px;
                        }
                    }

                    .dropdown-menu {
                        background: rgba($black, .75);
                        border: none;
                        position: absolute !important;
                        border-radius: 0;
                        border-bottom-left-radius: .2rem;
                        border-bottom-right-radius: .2rem;
                        box-shadow: 0 .1rem 0rem rgba(0, 0, 0, 0);
                        @include transition(box-shadow $transition-duration-default $timing-func-deceleration);

                        &.dropdown-menu-right {
                            right: 0;
                            left: auto;
                        }

                        &.show {
                            box-shadow: 0 .2rem 2rem rgba(0, 0, 0, 0.3);
                        }

                        &:focus,
                        &:hover {
                            box-shadow: 0 .2rem 5rem rgba(0, 0, 0, 0.5);
                        }

                        .dropdown-header {
                            color: $white;
                            font-size: .7rem;
                            border-left: $border-bottom-width solid rgba($black, 0)
                        }

                        .dropdown-divider {
                            border-top: $seperator-width solid $divider-color;
                            margin: .5rem 1.6rem;
                        }

                        .dropdown-item {
                            color: $item-color;
                            border-left: $border-bottom-width solid rgba($white, 0);
                            background: none !important;
                            @include transition;

                            &:focus,
                            &:hover,
                            &.active {
                                color: $primary;
                                border-left-color: $primary;
                            }
                        }
                    }
                }
            }

            .searchbar {
                flex: 1 1 auto;
                display: flex;
                align-items: stretch;

                input {
                    display: block;
                    flex: 1;
                    width: 100%;
                    min-width: 150px;
                    min-height: 30px;
                    border-radius: 99px;
                    padding: 0 .5rem;
                    cursor: pointer;
                    border: 1px solid $black;
                    background: $white;
                }
            }

            .nav-item:not(:first-child), .searchbar:not(:first-child) {
                margin-left: map-get($spacers, 1);
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:2178";