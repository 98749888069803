.img-emphasize {
    $ie-border-color-light: $gray-300;
    $ie-border-color-dark: $gray-600;
    $ie-border-length: 10%;
    $ie-border-width: 1px;

    @mixin border-image($color, $length) {
        $ct: rgba(0, 0, 0, 0);
        border-image-source: linear-gradient(-45deg, $color 0%, $color $length - .1%, $ct $length, $ct 100% - $length, $color 100% - $length - .1%, $color 100%);
    }

    padding: map-get($spacers, 2);
    border-width: $ie-border-width;
    border-style: solid;
    border-color: transparent;
    border-image-slice: 1;
    border-image-width: $ie-border-width;
    border-image-repeat: stretch;
    @include border-image($ie-border-color-light, $ie-border-length);

    /*&:hover,
    &:focus {
        @include border-image($ie-border-color-light, $ie-border-length * 2);
    }*/

    &.dark {
        @include border-image($ie-border-color-dark, $ie-border-length);

        /*&:hover,
        &:focus {
            @include border-image($ie-border-color-dark, $ie-border-length * 2);
        }*/
    }
}

;@import "sass-embedded-legacy-load-done:2367";