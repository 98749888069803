.page-header {
    $theme-background-gradient-opacity: .7;

    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: $gray-800;
    background-image: linear-gradient(-225deg, #473B7B 0%, #3584A7 51%, #30D2BE 100%);

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: map-get($spacers, 4) + $navbar-main-height + $navbar-tabs-height;
        padding-bottom: map-get($spacers, 4);
        min-height: 300px;
        @include transition(all $transition-duration-default ease-in-out);
    }

    &[data-aos="page-header"].aos-animate {
        .container {
            padding-top: (map-get($spacers, 2) + $navbar-main-height);
            padding-bottom: map-get($spacers, 2);
        }
    }

    @mixin mobile-heading-base {
        line-height: 1.1em;
    }

    @mixin desktop-heading-base {
        line-height: 1.2em;
    }

    @mixin theme-light() {
        color: $white;

        .container {
            background-image: radial-gradient(circle farthest-side at center bottom, rgba(0, 0, 0, $theme-background-gradient-opacity) 0%,rgba(0, 0, 0, 0) 100%);
        }
    }

    @mixin theme-dark {
        color: $black;

        .container {
            background-image: radial-gradient(circle farthest-side at center bottom, rgba(255, 255, 255, $theme-background-gradient-opacity) 0%,rgba(255, 255, 255, 0) 100%);
        }
    }

    h1 {
        @include mobile-heading-base;
        text-align: center;
        text-transform: none;
        font-size: 1.3rem;
        margin: .3rem 0;

        &.hide-overflow {
            span {
                display: block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    h2, h3  {
        @include mobile-heading-base;
        text-align: center;
        // text-transform: uppercase;
        font-weight: 300;
        font-size: .8rem;
        margin: .2rem 0;
    }

    h1 + .navbar-component, h2 + .navbar-component, h3 + .navbar-component {
        margin-top: .3rem;
    }

    &.theme-light {
        @include theme-light;
    }

    &.theme-dark {
        @include theme-dark;
    }

    @include media-breakpoint-down($breakpoint-mobile) {
    }

    @include media-breakpoint-up($breakpoint-desktop) {
        .container {
            padding-top: (map-get($spacers, 5) + $navbar-main-height);
            padding-bottom: map-get($spacers, 5);
            padding-left: ($grid-gutter-width / 2);
            padding-right: ($grid-gutter-width / 2);
        }

        &[data-aos="page-header"].aos-animate {
            .container {
                padding-top: (map-get($spacers, 3) + $navbar-main-height);
                padding-bottom: map-get($spacers, 3);
            }
        }

        h1 {
            @include desktop-heading-base;
            font-size: 1.6rem;
        }

        h2, h3  {
            @include desktop-heading-base;
            font-size: 1rem;
        }

        h1 + .navbar-component, h2 + .navbar-component, h3 + .navbar-component {
            margin-top: .5rem;
        }
    }
}

.no-page-header {
    margin-top: $navbar-main-height + $navbar-tabs-height;

    @include media-breakpoint-up($breakpoint-desktop) {
        margin-top: map-get($spacers, 2) + $navbar-main-height;
    }
}
;@import "sass-embedded-legacy-load-done:2378";