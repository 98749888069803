.scrollable-footer-partial {
    $headingMargin: 1.5em;
    $footer-bg-color: $gray-900;
    $footer-border-color: $gray-300;
    $footer-border-width: 0.3rem;

    padding: map-get($spacers, 4) 0 120px 0;
    color: $gray-300;
    border-top: $footer-border-width solid $footer-border-color;
    background: $footer-bg-color;
    font-size: $font-size-small;

    a {
        color: $white;
        font-weight: $font-weight-bold;

        &:hover, &:focus {
        }
    }

    .footer-navigation {
        .row > div {
            margin-bottom: map-get($spacers, 4);
        }

        .group-title {
            font-weight: $font-weight-bold;
            color: $white;
            font-size: $font-size-large;
        }

        .nav-item {
            a {
                font-size: $font-size-base;
                color: $gray-300;
                font-weight: $font-weight-normal;
                padding: 0.3rem 0;

                &:hover, &:focus {
                    color: $white;
                }
            }
        }

        .nav-item-seperator {
            border-bottom: 0.1rem solid $gray-600;
            margin: 0.1rem 0;
        }
    }

    .story-heading {
        font-size: 2rem;
        line-height: 2.5rem;
        letter-spacing: 0.1rem;
        margin-top: $headingMargin;
        margin-bottom: $headingMargin/2;
        font-weight: $font-weight-bold;
        color: $white;
    }

    .footer-story {
        @extend .text-justify;
        @extend %hyphens;
        font-weight: $font-weight-light;
        font-size: $font-size-base;
        line-height: 1.8rem;
        padding: .2rem .8rem;
    }

    .story-paragraph {
        font-size: $font-size-base;
        line-height: 2em;
        letter-spacing: 0.1em;
        padding: 0 1em;
        font-style: italic;
        color: rgba(0, 0, 0, 0.6);
    }

    .coop-heading {
        @extend .story-heading;
        font-size: $font-size-big;
        line-height: 2rem;
    }

    .coop-brands > a > span {
        padding: map-get($spacers, 3);
    }

    .disclaimer {
        text-align: justify;
        margin-top: $headingMargin;
    }

    @include media-breakpoint-up(lg) {
        .footer-story {
            columns: 2;
            column-gap: 1.4rem;
        }
    }
}

;@import "sass-embedded-legacy-load-done:2381";