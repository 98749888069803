@import "../../../Assets/stylesheets/bootstrap/variables";
@import "~bootstrap/scss/mixins/breakpoints";

&.style-default {
    $item-color: $black;
    $item-border-width: .2rem;
    $nav-bg-color: rgba($white, .9);
    $nav-border-width: .2rem;
    $nav-border-color: $brand-primary;

    border-top: $nav-border-width solid $nav-border-color;
    border-image: $brand-gradient-border 1 1 100%;
    border-bottom-width: 0;
    background: $nav-bg-color;

    .nav {
        .nav-item {
            .nav-link {
                font-weight: $font-weight-normal;
                font-size: $font-size-small;
                color: $item-color;
                text-transform: uppercase;
                padding: (.4rem + $item-border-width) .6rem .4rem .6rem;
                border-bottom: $item-border-width solid rgba($black, 0);
                transition: border .35s $timing-func-standard;
                @include transition(border $transition-duration-default $timing-func-deceleration);
            }

            &:focus,
            &:hover,
            &:active {
                .nav-link {
                    background: rgba(0, 0, 0, .03);
                }
            }

            &.active {
                .nav-link {
                    font-weight: $font-weight-bold;
                    border-bottom-color: $black;
                    background: rgba(0, 0, 0, .03);
                }
            }
        }
    }
    // Scroll overflow indicator
    .start-overflow-indicator {
        background: linear-gradient(to right, rgba($nav-bg-color, 1) 15%, rgba($nav-bg-color, 0) 100%);
    }

    .end-overflow-indicator {
        background: linear-gradient(to left, rgba($nav-bg-color, 1) 15%, rgba($nav-bg-color, 0) 100%);
    }

    .start-overflow-indicator, .end-overflow-indicator {
        .icon {
            color: $item-color;
        }
    }
}

;@import "sass-embedded-legacy-load-done:2219";