$aos-distance: 50px;

[data-aos='fade-up'] {
  transform: translate3d(0, $aos-distance, 0);
}

[data-aos='fade-down'] {
  transform: translate3d(0, -$aos-distance, 0);
}

[data-aos='fade-right'] {
  transform: translate3d(-$aos-distance, 0, 0);
}

[data-aos='fade-left'] {
  transform: translate3d($aos-distance, 0, 0);
}

[data-aos='fade-up-right'] {
  transform: translate3d(-$aos-distance, $aos-distance, 0);
}

[data-aos='fade-up-left'] {
  transform: translate3d($aos-distance, $aos-distance, 0);
}

[data-aos='fade-down-right'] {
  transform: translate3d(-$aos-distance, -$aos-distance, 0);
}

[data-aos='fade-down-left'] {
  transform: translate3d($aos-distance, -$aos-distance, 0);
}

@keyframes blink {
    50% {
        color: transparent
    }
}

.loader__dot {
    animation: 1s blink infinite
}

.loader__dot:nth-child(2) {
    animation-delay: 250ms
}

.loader__dot:nth-child(3) {
    animation-delay: 500ms
}
;@import "sass-embedded-legacy-load-done:2387";