.text-justify-hyphens {
    @extend .text-justify;
    hyphens: auto;
}

.font-size-default {
    font-size: $font-size-base;
}

.font-size-big {
    font-size: $font-size-big;
}

.font-size-large {
    font-size: $font-size-large;
}

.font-size-smaller {
    font-size: $font-size-smaller;
}

.font-size-small {
    font-size: $font-size-small;
}

.font-size-tiny {
    font-size: $font-size-tiny;
}

;@import "sass-embedded-legacy-load-done:2423";