@import "../../Assets/stylesheets/bootstrap/variables";

.scroll-to-top-component {
    $size: 38px;
    $inset: $grid-gutter-width / 2 + 5px;

    width: $size;
    height: $size;
    position: absolute;
    top: ($size + $footer-floating-btn-spacing) * -1;
    left: $inset;
    display: flex;
    background: rgba(0, 0, 0, 0.4);
    border-radius: $border-radius;
    text-decoration: none !important;
    opacity: 0;
    justify-content: center;
    align-items: center;
    z-index: $zindex-dropdown - 1;
    @include transition(opacity $transition-duration-default $timing-func-deceleration);

    &.visible {
        opacity: 1;
    }

    i.icon {
        color: white;
        display: block;
        font-size: 30px;
    }
}
