header {
    &::after {
        clear: both;
    }
}

.gl-app-2nd-level-nav {
    @include media-breakpoint-down($breakpoint-mobile) {
        header {
        }
    }
}

;@import "sass-embedded-legacy-load-done:2377";